import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const TicketsSkeleton = () => {
  return (
    <>
      <ListItem dense>
        <ListItemAvatar>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width={60} />}
          secondary={<Skeleton animation="wave" height={20} width={90} />}
        />
      </ListItem>
      <Divider variant="inset" />
      <ListItem dense>
        <ListItemAvatar>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width={70} />}
          secondary={<Skeleton animation="wave" height={20} width={120} />}
        />
      </ListItem>
      <Divider variant="inset" />
      <ListItem dense>
        <ListItemAvatar>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width={60} />}
          secondary={<Skeleton animation="wave" height={20} width={90} />}
        />
      </ListItem>
      <Divider variant="inset" />
    </>
  );
};

export default TicketsSkeleton;
